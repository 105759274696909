import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, StyleType } from '../utils/prop-types'
import Heading from './heading'
import Text from './text'

const HeaderBlock = ({
  title,
  subtitle,
  description,
  titleType,
  subtitleType,
  descriptionType,
  style,
  descriptionStyle,
}) => {
  return (
    <div css={[tw`flex flex-col items-start`, style]}>
      {subtitle && (
        <Text
          parentEl={subtitleType}
          content={subtitle}
          style={[
            global`typography.subtitle`,
            tw`mb-4 font-bold text-primary-500 text-opacity-47 uppercase lg:(capitalize mb-7)`,
          ]}
        />
      )}
      <Heading headingType={titleType} content={title} style={tw`font-bold text-primary-500`} />
      {description && (
        <Text
          parentEl={descriptionType}
          content={description}
          style={[descriptionStyle, tw`mt-4 lg:mt-6`]}
        />
      )}
    </div>
  )
}

HeaderBlock.defaultProps = {
  titleType: 'h3',
  subtitleType: 'div',
  descriptionType: 'div',
}

HeaderBlock.propTypes = {
  title: HTMLType,
  subtitle: HTMLType,
  description: HTMLType,
  titleType: PropTypes.string,
  subtitleType: PropTypes.string,
  descriptionType: PropTypes.string,
  style: StyleType,
  descriptionStyle: StyleType,
}

export default HeaderBlock
