import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { ImageType, StyleType } from '../utils/prop-types'
import Image from './image'
import { ReactComponent as Arrow } from '../images/icon-arrow-right-cyan.svg'

const Gallery = ({ images, style }) => {
  const [viewportRef] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  })

  return (
    <div css={tw`relative w-full`}>
      <div
        css={[
          css`
            ${tw`overflow-visible`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
          style,
        ]}
        ref={viewportRef}
      >
        <div css={tw`flex flex-row items-end w-full flex-nowrap`}>
          {images.map((image, i) => (
            <div key={i} css={tw`flex-none w-58 pr-5 lg:(flex-none w-116 pr-12 last:pr-0)`}>
              <Image image={image} />
            </div>
          ))}
        </div>
      </div>
      <Arrow css={tw`absolute w-32 right-1/4 top-1/3 lg:top-1/2`} />
    </div>
  )
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(ImageType),
  style: StyleType,
}

export default Gallery
